import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
	useDisclosure,
	Box,
	HStack,
	useToast,
	useBreakpointValue
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { MarkdownContent } from 'modules/common/components/markdown-content'
import { useJourney } from 'modules/journeys/hooks'
import { pointChannel } from 'modules/map/modules/point/point_channel'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import { useMapStore } from '../../../map-canvas/store'
import { useNoticePoint } from '../../hooks'
import { NoticePointModal } from '../../notice-point-modal'
import { deleteNoticePoint } from '../../usecases'
import { CreateNoticePointBody } from '../create-notice-point'
import { EditNoticePointFooter } from '../edit-notice-point'
import { OptionsNoticePoint } from '../options'

// Define markdown component types

export const ContentNoticePoint: FC<{ pointId: string }> = ({ pointId }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const { id: projectId = '', mapId = '' } = useParams<{
		id: string
		mapId: string
	}>()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const { data: point, isLoading } = useNoticePoint(pointId)
	const pointDate = new Date(point ? point.created_at : '')
	const pointMessage = point?.description
	const sentEmail = point?.send_email
	const value = useMemo(() => pointMessage, [pointMessage])
	const [editPoint, setEditPoint] = useState(false)
	const hasSelectedPoint = useMapStore(({ state }) => !!state.selectedPoint)
	const { removePoint, clearSelected } = useMapStore(({ actions }) => actions)
	const backRouter = location.pathname.replace(`/point/${pointId}`, '')
	const isAPointUrl = location.pathname.includes('point')
	const toast = useToast()
	const [newNotice, setNewNotice] = useState(pointMessage || '')

	const disclosure = useDisclosure({
		isOpen: hasSelectedPoint,
		onClose: () => {
			clearSelected()
			if (isAPointUrl) {
				navigate(backRouter)
			}
		}
	})

	const handleDeletePoint = async () => {
		if (point) {
			try {
				await deleteNoticePoint(pointId)
				toast({
					title: t('map:points.conversation.delete.success'),
					status: 'success'
				})
				removePoint(point)
				clearSelected()
				const pointType = 'NOTICE'
				const pointInfo = {
					id: pointId,
					position: point.position,
					point_type: pointType
				}
				emit('DELETE', pointInfo)
				navigate('../')
			} catch (error) {
				const { message } = error as Error
				toast({ title: message, status: 'error' })
			}
		}
	}

	return (
		<>
			{!isLoading && !!value && (
				<NoticePointModal
					disclosure={disclosure}
					body={
						editPoint ? (
							<CreateNoticePointBody setValue={setNewNotice} message={value} />
						) : (
							<ViewNoticePointBody
								message={value}
								setEditPoint={setEditPoint}
								editPoint={editPoint}
								handleDeletePoint={handleDeletePoint}
								pointDate={pointDate}
								projectId={projectId}
							/>
						)
					}
					footer={
						editPoint ? (
							<EditNoticePointFooter
								pointId={pointId}
								newNotice={newNotice || ''}
								sentEmail={sentEmail || true}
							/>
						) : null
					}
				/>
			)}
		</>
	)
}

type PropsBody = {
	message: string
	editPoint: boolean
	setEditPoint: Dispatch<SetStateAction<boolean>>
	pointDate: Date
	handleDeletePoint: () => void
	projectId: string
}

const ViewNoticePointBody: FC<PropsBody> = (props) => {
	const {
		message,
		editPoint,
		setEditPoint,
		pointDate,
		handleDeletePoint,
		projectId
	} = props
	const { i18n } = useTranslation()
	const createdAt = pointDate.toLocaleDateString(i18n.language)
	const isMobile = useBreakpointValue({ base: true, md: false })

	const { user: currUser } = useMe()
	const { data: users = [] } = useJourney(projectId, {
		select: (journey) => journey.users
	})
	const hasPrivileges = !!users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) =>
			Role(project_roles).can('notice_point.options')
		)

	return (
		<>
			<HStack position='relative'>
				<Box
					h='full'
					w='full'
					overflow='auto'
					px={isMobile ? 4 : 10}
					py={10}
					_hover={{ borderColor: 'red.400' }}
					borderRadius='lg'
					backgroundColor='gray.100'
				>
					<MarkdownContent content={message} />
				</Box>
				{hasPrivileges && (
					<Box top='1' right='1' position='absolute'>
						<OptionsNoticePoint
							pointDate={createdAt}
							onDelete={handleDeletePoint}
							onEdit={() => setEditPoint(!editPoint)}
						/>
					</Box>
				)}
			</HStack>
		</>
	)
}
