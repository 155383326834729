import { useTranslation } from 'react-i18next'

import { Box, Text } from '@chakra-ui/react'

import { MarkdownContent } from 'modules/common/components/markdown-content'

type Props = {
	content: string
	count: number
}

export const MotivationText = ({ content, count }: Props) => {
	const { t } = useTranslation()

	return (
		<>
			<Text color='gray.300' fontSize='sm'>
				{t('evaluationPoint:view.supportText', { count })}
			</Text>
			<Box p={6} borderRadius='md' backgroundColor='gray.100'>
				<MarkdownContent content={content} />
			</Box>
		</>
	)
}
