import { Flex } from '@chakra-ui/layout'

import { MarkdownContent } from 'modules/common/components/markdown-content'
import { useMapStore } from 'modules/map/modules/map-canvas/store'

export const IntroductionCard = ({
	introduction
}: {
	introduction: string
}) => {
	const color = useMapStore(({ state }) => state.selectedPoint?.color)

	return (
		<Flex
			w='full'
			mt='2'
			px='1.125rem'
			pt='1.125rem'
			pb='1.125rem'
			flexDirection='column'
			borderRadius='8'
			border='1px solid'
			borderColor={color}
			backgroundColor='#fff'
		>
			<MarkdownContent content={introduction} />
		</Flex>
	)
}
